import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "@material-ui/core"
import CarouselSection from "../CarouselSection"
import useRackSize from "../useRackSize"
import TourCard from "./TourCard"

const TourCardCarousel = ({ locale, title, subtitle, currencyString }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTourPageModel {
        edges {
          node {
            node_locale
            tourDetailCardsReferences {
              slug
              tourLocationDescription
              tourTitle
              tourPricingReference {
                isFixedPriceTour
                fixedTourPrice
                perPersonPricing
              }
              cardDescription {
                cardDescription
              }
              tourMainImage {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const localeCardsData = data.allContentfulTourPageModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )

  const tourCards = localeCardsData[0].node.tourDetailCardsReferences
  const rackSize = useRackSize()

  return (
    <CarouselSection
      title={title}
      description={subtitle}
      cardsData={tourCards}
      rackSize={rackSize}
    >
      {tourCards.map(
        ({
          slug,
          tourLocationDescription,
          tourTitle,
          cardDescription,
          tourMainImage,
          tourPricingReference,
        }) => (
          <Box
            key={slug}
            p={1.5}
            style={{ minWidth: `${100 / rackSize}%`, flexBasis: "25%" }}
          >
            <TourCard
              slug={slug}
              tourLocationDescription={tourLocationDescription}
              tourTitle={tourTitle}
              cardDescription={cardDescription}
              tourMainImage={tourMainImage}
              tourPricingReference={tourPricingReference}
              currencyString={currencyString}
            />
          </Box>
        )
      )}
    </CarouselSection>
  )
}

export default TourCardCarousel

/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import {
  Card,
  CardContent,
  Box,
  Typography,
  Hidden,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
  Snackbar,
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import ArrowForward from "@material-ui/icons/ArrowForward"
import ScheduleIcon from "@material-ui/icons/Schedule"
import { makeStyles } from "@material-ui/core/styles"
import DateFnsUtils from "@date-io/date-fns"
import PeopleMenu from "./PeopleMenu"
import getTotalEur from "./getTotalEur"
import Axios from "axios"
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles(theme => ({
  stickyCard: {
    marginLeft: 0,
    top: 20,
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      position: "sticky",
    },
    transition: "all 0.3s ease",
  },
  stickyCardContent: {
    height: "100%",
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  inputLabel: {
    position: "unset",
    transform: "unset",
  },
  iconOpen: {
    transform: "unset",
  },
  extrasLabel: {
    fontSize: "0.75rem",
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))

const TourStickyCard = ({
  translations,
  eachIncrementalExtra,
  eachIncrementalExtraPricing,
  eachFixedPriceExtraText,
  eachFixedPriceExtraPricing,
  perPersonPricing,
  childPricingPercentageToAdult,
  fixedTourPrice,
  isFixedPriceTour,
  setShowSticky,
  stickyNoteTitle,
  isSmall,
  tourTimings,
  couponDiscountActual,
  couponCodeActual,
  disableDaysLimit,
  disableDates,
  disableWeekDays,
  isDisableCalender,
  tourTitle,
  totalPersonsLimit,
  enablePayment,
}) => {
  const classes = useStyles()

  const [adults, setAdults] = useState(0)
  const [myChildren, setChildren] = useState(0)
  const [infants, setInfants] = useState(0)
  const [isTourAvailable, setIsTourAvailable] = useState(false)
  const [tourAvailability, setTourAvailability] = useState(0)
  const [incrementalExtras, setIncrementalExtras] = useState(
    eachIncrementalExtra?.map((extra, index) => ({
      title: extra,
      price: eachIncrementalExtraPricing[index],
      selected: false,
      isFixedPrice: false,
    }))
  )

  const [fixedExtras, setFixedExtras] = useState(
    eachFixedPriceExtraText?.map((extra, index) => ({
      title: extra,
      price: eachFixedPriceExtraPricing[index],
      selected: false,
      isFixedPrice: true,
    }))
  )

  const [isFixedTour, setIsFixedTour] = useState(false)
  const [total, setTotal] = useState(0)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [totalTourTime, setTotalTourTime] = useState(tourTimings[0])
  const [couponCode, setCouponCode] = useState("")
  const [stickyCardHeight, setStickyCardHeight] = useState('91vh')

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        setStickyCardHeight('91vh')
      } else {
        setStickyCardHeight('95vh')
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setTotal(
      getTotalEur(
        adults,
        myChildren,
        perPersonPricing,
        childPricingPercentageToAdult,
        incrementalExtras,
        fixedExtras,
        isFixedTour,
        fixedTourPrice,
        isFixedPriceTour
      )
    )
  }, [
    adults,
    myChildren,
    infants,
    incrementalExtras,
    fixedExtras,
    perPersonPricing,
    childPricingPercentageToAdult,
    isFixedTour,
    fixedTourPrice,
    isFixedPriceTour,
  ])

  const [alert, setAlert] = useState({
    message: "Something went wrong",
    severity: "error",
    isOpen: false,
  })

  const verifyAvailability = useCallback(async () => {
    const response = await Axios.get(
      `${
        process.env.API_URL
      }/tour?title=${tourTitle}&schedule=${totalTourTime}&date=${selectedDate.toDateString()}&limit=${totalPersonsLimit}`
    )
    if (response.status === 200) {
      if (response.data.availability > 0) {
        setAlert({
          message: "There are some spaces available",
          severity: "success",
          isOpen: true,
        })
        setTourAvailability(response.data.availability)
        setIsTourAvailable(true)
      } else {
        setAlert({
          message: "There are no spaces available",
          severity: "error",
          isOpen: true,
        })
        setTourAvailability(response.data.availability)
        setIsTourAvailable(false)
      }
    }
  }, [selectedDate, totalTourTime, tourTitle, totalPersonsLimit])

  useEffect(() => {
    if (enablePayment === true) {
      verifyAvailability()
    } else {
      setAlert({
        message: "Payment is disabled for this tour",
        severity: "error",
        isOpen: true,
      })
    }
  }, [enablePayment, selectedDate, verifyAvailability])

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleModifyExtras = (event, extra) => {
    const newIncrementalExtras = incrementalExtras.map(item =>
      item.title === extra
        ? {
            title: item.title,
            price: item.price,
            selected: event.target.checked,
            isFixedPrice: false,
          }
        : item
    )
    const newFixedExtras = fixedExtras.map(item =>
      item.title === extra
        ? {
            title: item.title,
            price: item.price,
            selected: event.target.checked,
            isFixedPrice: true,
          }
        : item
    )
    setIncrementalExtras(newIncrementalExtras)
    setFixedExtras(newFixedExtras)
  }

  const disableDays = date => {
    for (var i = 0; i <= disableWeekDays.length; i++) {
      if (date.getDay() === disableWeekDays[i]) {
        return true
      }
    }
  }

  return (
    <Card className={classes.stickyCard} style={{ height: stickyCardHeight}}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({
            isOpen: false,
            message: "",
          })
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
      <CardContent classes={{ root: classes.stickyCardContent }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Typography variant="overline">
                {translations?.bookNow}
              </Typography>
              <Hidden mdUp>
                <IconButton onClick={() => setShowSticky(false)} edge="end">
                  <CancelOutlinedIcon fontSize="large" />
                </IconButton>
              </Hidden>
            </Box>
            <Typography variant="h6">{stickyNoteTitle}</Typography>
            <Box mt={0.5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  variant={isSmall ? "dialog" : "inline"}
                  value={selectedDate}
                  placeholder="10/10/2018"
                  onChange={date => handleDateChange(date)}
                  minDate={new Date()}
                  format="yyyy-MM-dd"
                  fullWidth
                  KeyboardButtonProps={{ edge: "end" }}
                  label={translations?.selectDate}
                  InputLabelProps={{
                    classes: { formControl: classes.inputLabel },
                    shrink: false,
                  }}
                  shouldDisableDate={disableDays}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <FormControl>
              <FormLabel>{translations?.selectTime}</FormLabel>
              <Select
                IconComponent={ScheduleIcon}
                value={totalTourTime}
                classes={{ iconOpen: classes.iconOpen }}
                onChange={event => setTotalTourTime(event.target.value)}
              >
                {tourTimings.map(option => (
                  <MenuItem key={option} value={option} selected={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <PeopleMenu
              adults={adults}
              myChildren={myChildren}
              infants={infants}
              setAdults={setAdults}
              setChildren={setChildren}
              setInfants={setInfants}
              translations={translations}
              iconPosition="end"
              perPersonPricing={perPersonPricing}
              totalPersonsLimit={tourAvailability}
            />

            {isFixedPriceTour ? (
              <FormControl>
                <List>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={translations?.tourPrice}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                    <ListItemIcon>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            checked={isFixedTour}
                            onChange={event =>
                              setIsFixedTour(event.target.checked)
                            }
                          />
                        }
                        label={`${fixedTourPrice} ${translations?.euro}`}
                        labelPlacement="start"
                        classes={{ label: classes.extrasLabel }}
                      />
                    </ListItemIcon>
                  </ListItem>
                </List>
              </FormControl>
            ) : (
              <FormControl>
                <FormLabel>{translations?.addExtras}</FormLabel>
                <List>
                  {incrementalExtras?.map((extra, index) => (
                    <ListItem
                      key={extra.title}
                      disableGutters
                      divider={
                        index < eachIncrementalExtra.length - 1
                          ? true
                          : eachFixedPriceExtraText?.length === 0
                          ? false
                          : true
                      }
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <ListItemText
                        primary={extra.title}
                        primaryTypographyProps={{ variant: "body2" }}
                      />
                      <ListItemIcon>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              checked={extra.selected}
                              onChange={event =>
                                handleModifyExtras(event, extra.title)
                              }
                            />
                          }
                          label={`${extra.price} ${translations?.euro}`}
                          labelPlacement="start"
                          classes={{ label: classes.extrasLabel }}
                        />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                  {fixedExtras?.map((extra, index) => (
                    <ListItem
                      key={extra.title}
                      disableGutters
                      divider={index < fixedExtras.length - 1 ? true : false}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <ListItemText
                        primary={extra.title}
                        primaryTypographyProps={{ variant: "body2" }}
                      />
                      <ListItemIcon>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              checked={extra.selected}
                              onChange={event =>
                                handleModifyExtras(event, extra.title)
                              }
                            />
                          }
                          label={`${extra.price} ${translations?.euro}`}
                          labelPlacement="start"
                          classes={{ label: classes.extrasLabel }}
                        />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </FormControl>
            )}
          </Box>
          <Box>
            <Divider style={{ marginLeft: "-24px" }} />
            <Box display="flex" justifyContent="space-between" pt={2}>
              <Box>
                <Typography style={{ fontSize: "14px", lineHeight: "18px" }}>
                  {translations?.total}
                </Typography>
                <Box display="flex" alignItems="end">
                  <Typography variant="h6">{Math.round(total)}</Typography>
                  <Typography
                    style={{
                      fontSize: "0.75rem",
                      lineHeight: "24px",
                      paddingLeft: "4px",
                    }}
                  >
                    {translations?.euro}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                endIcon={
                  <div className={classes.arrow}>
                    <ArrowForward />
                  </div>
                }
                disabled={!isTourAvailable || !adults}
                onClick={() => {
                  navigate("/CheckOut", {
                    state: {
                      couponDiscount: couponDiscountActual,
                      date: selectedDate,
                      time: totalTourTime,
                      couponCode:
                        couponCode === couponCodeActual ? couponCode : false,
                      timesarray: tourTimings,
                      datesarray: {
                        disableDaysLimit,
                        disableWeekDays,
                        disableDates,
                        isDisableCalender,
                      },
                      adults,
                      children: myChildren,
                      infants,
                      perPersonPricing,
                      childPricingPercentageToAdult,
                      totalPersonsLimit,
                      allSelectedExtras: isFixedPriceTour
                        ? []
                        : incrementalExtras
                            .concat(fixedExtras)
                            .filter(extra => extra.selected),
                      title: tourTitle,
                      total: Math.round(total),
                      incrementalExtras,
                      fixedExtras,
                      isFixedTour,
                      fixedTourPrice,
                      isFixedPriceTour,
                      tourAvailability,
                    },
                  })
                }}
              >
                {translations?.bookNowSmall}
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TourStickyCard

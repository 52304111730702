import React, { useState } from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Typography, Box, Avatar, Container, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import "./tripAdvisorStyles.css"
import Swipe from "react-easy-swipe"

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  carousel: {
    // marginTop: the me.spacing(6),
    transition: "transform 0.2s ease 0s",

    // Add margins to carousel so overflow card edges are visible
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
  },
  cardWrapper: {
    flexBasis: "25%",
    padding: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}))

const TripAdvisor = ({ translations, locale, width }) => {
  const classes = useStyles()
  const { file, allContentfulTourPageModel } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "tripadvisor_new" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allContentfulTourPageModel {
          edges {
            node {
              tripAdvisorComments {
                reviewer
                review
              }
              node_locale
              tripAdvisorCommentsThumbnails {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    tripAdvisorComments,
    tripAdvisorCommentsThumbnails,
  } = allContentfulTourPageModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )[0].node

  const Carousel = () => {
    const getRackSize = () => {
      switch (width) {
        case "xl":
          return 6
        case "lg":
          return 6
        case "md":
          return 4
        case "sm":
          return 3
        default:
          return 1.5
      }
    }
    const rackSize = getRackSize()
    const [carouselIndex, setCarousel] = useState(0)

    const onClickLeft = () => {
      const increment = carouselIndex <= 0 ? 0 : -1
      setCarousel(carouselIndex + increment)
    }

    const onClickRight = () => {
      const increment =
        carouselIndex >= tripAdvisorComments.length - rackSize ? 0 : 1
      setCarousel(carouselIndex + increment)
    }

    return (
      <Swipe
        onSwipeLeft={() => onClickRight()}
        onSwipeRight={() => onClickLeft()}
      >
        <Box
          display="flex"
          className={classes.carousel}
          style={{
            transform: `translateX(${(carouselIndex * -100) / rackSize}%)`,
          }}
        >
          <Hidden smDown>
            <Box
              className={classes.cardWrapper}
              style={{ minWidth: `${100 / rackSize}%` }}
            >
              <TitleContent />
            </Box>
          </Hidden>
          {tripAdvisorComments.map(({ reviewer, review }, index) => (
            <Box
              key={index}
              className={classes.cardWrapper}
              style={{ minWidth: `${100 / rackSize}%` }}
            >
              <Typography style={{ fontFamily: "MabryProRegular" }}>
                {review}
              </Typography>
              <Box display="flex" alignItems="center" mt={1}>
                <Box mr={1}>
                  <Avatar
                    style={{ width: "24px", height: "24px" }}
                    alt="Stock avatar"
                    src={tripAdvisorCommentsThumbnails[index]?.fluid?.src}
                  />
                </Box>
                <Typography
                  style={{ fontSize: "0.875rem", lineHeight: "18px" }}
                >
                  {reviewer}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Swipe>
    )
  }

  const TitleContent = () => (
    <>
      <Typography>{translations?.recommendedOn}</Typography>
      <Box pt={1} width="160px">
        <a
          href="https://www.tripadvisor.com/Attraction_Review-g189180-d12707834-Reviews-Zhutravel-Porto_Porto_District_Northern_Portugal.html"
          target="__blank"
        >
          <Img fluid={file?.childImageSharp?.fluid} />
        </a>
      </Box>
      <Helmet>
        <script
          async
          src="https://www.jscache.com/wejs?wtype=cdsratingsonlywide&amp;uniq=281&amp;locationId=12707834&amp;lang=en_UK&amp;border=false&amp;shadow=false&amp;backgroundColor=gray&amp;display_version=2"
          data-loadtrk
          onload="this.loadtrk=true"
        ></script>
      </Helmet>
      <div id="TA_cdsratingsonlywide281" class="TA_cdsratingsonlywide">
        <ul id="xCuRgD" class="TA_links CUQTRnuUnMiG">
          <li id="SHDfNKB" class="E64NrW">
            Loading
          </li>
        </ul>
      </div>
    </>
  )

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <Container>
          <TitleContent />
        </Container>
      </Hidden>
      <Carousel />
    </div>
  )
}

export default TripAdvisor

import React from "react"
import Img from "gatsby-image"
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LocalizedLink from "../LocalizedLink"

const useStyles = makeStyles({
  card: {
    boxShadow: "0 0 5px 0 rgba(1,2,4,0.04)",
  },
  dFlex: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    marginTop: 5,
  },
  media: {
    height: "172px",
  },
  title: {
    color: "#010204",
    fontFamily: "Mabry Pro",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "26px",
  },
  subtitle: {
    color: "#010204",
    fontFamily: "Mabry Pro",
    fontSize: "12px",
    fontWeight: "300",
    letterSpacing: "0.5px",
    lineHeight: "16px",
  },
  leftBMargin: {
    marginLeft: "5px",
    marginBottom: "2px",
  },
})

const TourCard = ({
  slug,
  tourLocationDescription,
  tourTitle,
  cardDescription,
  tourMainImage,
  tourPricingReference,
  currencyString,
}) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <LocalizedLink
        style={{ textDecoration: "none", color: "black" }}
        to={`/tour-detail/${slug}`}
      >
        <CardActionArea>
          <CardMedia>
            <Img fluid={tourMainImage?.fluid} className={classes.media} />
          </CardMedia>
          <CardContent>
            <Typography
              style={{
                fontFamily: "MabryProLight",
                fontSize: "12px",
              }}
            >
              {tourLocationDescription}
            </Typography>
            <Typography
              style={{ fontFamily: "MabryProBold", fontSize: "20px" }}
              gutterBottom
              className={classes.title}
            >
              {tourTitle}
            </Typography>
            <Typography
              style={{
                fontFamily: "MabryProLight",
                fontSize: "16px",
                overflow: "hidden",
                display: "-webkit-box",
                webkitLineClamp: "2",
                webkitBoxOrient: "vertical",
                minHeight: 32,
              }}
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.subtitle}
            >
              {cardDescription?.cardDescription}
            </Typography>
            <div className={[classes.dFlex]}>
              <div className={[classes.dFlex]}>
                <Typography
                  style={{
                    fontFamily: "MabryProBold",
                    fontSize: "20px",
                  }}
                  gutterBottom
                  className={classes.title}
                >
                  {!!tourPricingReference?.isFixedPriceTour
                    ? tourPricingReference.fixedTourPrice
                    : tourPricingReference.perPersonPricing}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "MabryProLight",
                    fontSize: "12px",
                  }}
                  className={[classes.leftBMargin, classes.subtitle]}
                >
                  {currencyString}
                </Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </LocalizedLink>
    </Card>
  )
}

export default TourCard

import React, { useState } from "react"
import Img from "gatsby-image"
import {
  Drawer,
  Container,
  Paper,
  Box,
  CssBaseline,
  Hidden,
  useMediaQuery,
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
  withWidth,
} from "@material-ui/core"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"
import LanguageIcon from "@material-ui/icons/Language"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty"
import ReactMapGL from "react-map-gl"
import { useSelector } from "react-redux"
import Footer from "../../components/Footer"
import { ImageSliderWithText } from "../../components/ImageSliderWithText"
import Textdetail from "../../components/TextField"
import TourCardCarousel from "../../components/TourCards/TourCardCarousel"
import zhutravelTheme from "../../components/Layout/zhutravelTheme"
import LocalizedLink from "../../components/LocalizedLink"
import TripAdvisor from "../../components/TripAdvisor"
import CustomDivider from "../../components/CustomDivider"
import TourStickyCard from "./TourStickyCard"
import { SEO } from "../../components/SEO"

const toursTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    secondary: {
      main: "#FF9486",
      contrastText: "#010204",
      dark: "#ff5252",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ul: {
          marginTop: zhutravelTheme.spacing(2),
          marginBottom: 0,
          paddingInlineStart: "13px",
          fontSize: "1rem",
          [zhutravelTheme.breakpoints.up("md")]: {
            paddingInlineStart: 0,
          },
        },
        li: {
          marginBottom: zhutravelTheme.spacing(0.5),
          listStyleType: "square",
        },
        "li::marker": {
          color: "#FF9486",
        },
      },
    },
    MuiTypography: {
      h1: {
        [zhutravelTheme.breakpoints.up("md")]: {
          fontSize: "2.5rem",
        },
      },
      h2: {
        fontFamily: "MabryProRegular",
        fontSize: "1.25rem",
      },
      h3: {
        fontFamily: "MabryProRegular",
        fontSize: "0.75rem",
        lineHeight: "16px",
        textTransform: "uppercase",
        marginBottom: zhutravelTheme.spacing(1),
      },
      subtitle2: {
        lineHeight: "18px",
      },
    },
    MuiCardContent: {
      root: {
        padding: zhutravelTheme.spacing(3),
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#FF9486",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#FF9486",
      },
    },
    MuiFormControl: {
      root: {
        marginTop: zhutravelTheme.spacing(3.5),
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "MabryProRegular",
      },
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: 0,
        },
      },
    },
  },
  props: {
    MuiInputLabel: {
      shrink: true,
    },
    MuiFormControl: {
      fullWidth: true,
    },
  },
})

const useStyles = makeStyles(theme => ({
  container: {
    padding: `0 ${zhutravelTheme.spacing(2.5)}px`,
    [theme.breakpoints.up("md")]: {
      padding: `0 ${zhutravelTheme.spacing(2)}px`,
    },
  },
  headerImage: {
    height: 160,
    borderRadius: "2px",
    [theme.breakpoints.up("md")]: {
      height: 360,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 -12px",
    },
  },
  sectionWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(12.5),
      marginBottom: theme.spacing(15),
    },
  },
  bulletIcon: {
    fontSize: "1rem",
    marginRight: theme.spacing(1),
  },
  bulletText: {
    fontFamily: "MabryProRegular",
    fontSize: "0.875rem",
    lineHeight: "18px",
  },
  bulletDivider: {
    borderRight: "2px dotted rgba(1,2,4,0.4)",
    backgroundColor: "inherit",
  },
  subsection: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  itineraryTitle: {
    fontSize: "1rem",
  },
  itineraryImage: {
    height: 80,
    borderRadius: "2px",
  },
  infoBlock: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  media: {
    height: "360px",
    width: "100%",
  },
  overlayNumber: {
    fontSize: "0.75rem",
    fontFamily: "MabryProRegular",
    color: "#FFF",
    lineHeight: "16px",
  },
  mobileButtonPaper: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.speedDial,
  },
}))

const TourDetail = ({
  enablePayment,
  width,
  couponCode: couponCodeActual,
  couponDiscount: couponDiscountActual,
  totalPersonsLimit,
  stickyNoteTitle,
  tourTitle,
  tourLocationDescription,
  tourTime,
  tourWaitingTime,
  tourLanguage,
  whatsIncludedTitle,
  whatsIncludedListItems,
  whyTourTitle,
  whyTourListItems,
  whyTourSubTitle,
  itinerarySectionTitle,
  itineraryTimings,
  itinerarySectionSubTitle,
  itineraryTitles,
  itinerarySubTitles,
  informationSectionTitle,
  meetingTime,
  meetingLocation,
  meetingLanguage,
  meetingCityLocation,
  meetingDuration,
  meetingNotIncludedBullets,
  meetingSpecialNotesBullets,
  tourMainImage,
  citySlug,
  cityName,
  itineraryImages,
  sliderImages,
  sliderImagesTitles,
  tourProgramPdf,
  tourDescription,
  disableDaysLimit,
  disableDates,
  disableWeekDays,
  isDisableCalender,
  fixedTourPrice,
  isFixedPriceTour,
  perPersonPricing,
  childPricingPercentageToAdult,
  eachIncrementalExtra,
  eachIncrementalExtraPricing,
  eachFixedPriceExtraText,
  eachFixedPriceExtraPricing,
  personsLimitForFixedPriceExtraIncrement,
  tourTimings,
  querySectionImage,
  seoTitle,
  seoDescription,
}) => {
  const classes = useStyles()
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.tourDetail
  )
  const locale = useSelector(state => state?.locale?.localeObj?.locale)

  const isSmall = useMediaQuery(toursTheme.breakpoints.down("sm"))

  const [mapViewport, setMapViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: meetingLocation?.lat,
    longitude: meetingLocation?.lon,
    zoom: 8,
  })

  const tourDetailBullets = [
    {
      label: tourTime,
      icon: <AccessTimeIcon className={classes.bulletIcon} />,
    },
    {
      label: tourWaitingTime,
      icon: <HourglassEmptyIcon className={classes.bulletIcon} />,
    },
    {
      label: tourLanguage,
      icon: <LanguageIcon className={classes.bulletIcon} />,
    },
  ]

  const itineraryItems = itineraryTitles.map((title, index) => ({
    title: title,
    subtitle: itinerarySubTitles[index],
    image: itineraryImages[index],
    timing: itineraryTimings[index],
  }))

  const [showSticky, setShowSticky] = useState(false)

  const StickyCard = () => (
    <TourStickyCard
      translations={translations}
      eachIncrementalExtra={eachIncrementalExtra}
      eachIncrementalExtraPricing={eachIncrementalExtraPricing}
      eachFixedPriceExtraText={eachFixedPriceExtraText}
      eachFixedPriceExtraPricing={eachFixedPriceExtraPricing}
      perPersonPricing={perPersonPricing}
      childPricingPercentageToAdult={childPricingPercentageToAdult}
      fixedTourPrice={fixedTourPrice}
      isFixedPriceTour={isFixedPriceTour}
      setShowSticky={setShowSticky}
      stickyNoteTitle={stickyNoteTitle}
      isSmall={isSmall}
      tourTimings={tourTimings}
      couponDiscountActual={couponDiscountActual}
      couponCodeActual={couponCodeActual}
      disableDaysLimit={disableDaysLimit}
      disableDates={disableDates}
      disableWeekDays={disableWeekDays}
      isDisableCalender={isDisableCalender}
      tourTitle={tourTitle}
      totalPersonsLimit={totalPersonsLimit}
      enablePayment={enablePayment}
    />
  )
  return (
    <div
      className={locale === "zh" && "chinese-version"}
      style={{ backgroundColor: "#FAF5EF" }}
    >
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <Hidden mdUp>
        <ThemeProvider theme={toursTheme}>
          <Drawer
            open={showSticky}
            anchor="bottom"
            onClose={() => setShowSticky(false)}
          >
            <StickyCard />
          </Drawer>
          <Paper className={classes.mobileButtonPaper}>
            <Button
              onClick={() => setShowSticky(true)}
              variant="contained"
              fullWidth
              color="secondary"
            >
              {translations?.bookNowSmall}
            </Button>
          </Paper>
        </ThemeProvider>
      </Hidden>
      <Container className={classes.container}>
        <ThemeProvider theme={toursTheme}>
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Img className={classes.headerImage} fluid={tourMainImage} />
              <Grid container justify="center" spacing={3}>
                <Grid item xs={12} md={6} className={classes.sectionWrapper}>
                  <section>
                    <Typography variant="overline">
                      {tourLocationDescription}
                    </Typography>
                    <Typography variant="h1">{tourTitle}</Typography>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: tourDescription }}
                    />
                    <Box display="flex" mt={4} justifyContent="space-between">
                      {tourDetailBullets.map(({ label, icon }, index) => (
                        <>
                          <Box key={label} display="flex">
                            {icon}
                            <Typography className={classes.bulletText}>
                              {label}
                            </Typography>
                          </Box>
                          {index < 2 && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              variant="middle"
                              className={classes.bulletDivider}
                            />
                          )}
                        </>
                      ))}
                    </Box>
                  </section>
                  <Box className={classes.subsection}>
                    <Typography variant="h2">{whatsIncludedTitle}</Typography>
                    <ul>
                      {whatsIncludedListItems?.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </Box>
                  <Card className={classes.subsection}>
                    <CardContent>
                      <Typography variant="h2">{whyTourTitle}</Typography>
                      <Typography>{whyTourSubTitle}</Typography>
                      <Box mt={3} display="flex" flexWrap="wrap">
                        {whyTourListItems?.map(item => (
                          <Box display="flex" mb={1.5} key={item}>
                            <Box mr={1} clone>
                              <CircleChecked
                                fontSize="small"
                                color="secondary"
                              />
                            </Box>
                            <Typography>{item}</Typography>
                          </Box>
                        ))}
                      </Box>
                      <Box mt={4} clone>
                        <Button
                          variant="outlined"
                          component={LocalizedLink}
                          to={`/destinations/${citySlug}`}
                        >
                          {translations?.discover} {cityName}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                  <div className={classes.subsection}>
                    <Typography variant="h2">
                      {itinerarySectionTitle}
                    </Typography>
                    <Typography>{itinerarySectionSubTitle}</Typography>
                    <Box mt={4}>
                      {itineraryItems.map(
                        ({ title, subtitle, image, timing }, index) => (
                          <>
                            <Box
                              mb={index < itineraryItems.length - 1 ? 3 : 0}
                              mt={3}
                              display="flex"
                            >
                              <Box position="relative" width={1 / 3} pr={2}>
                                <Img
                                  fluid={image.fluid}
                                  className={classes.itineraryImage}
                                />
                                <Box
                                  position="absolute"
                                  top="8px"
                                  left="8px"
                                  p={0.5}
                                  bgcolor="rgba(23,24,26,0.4)"
                                  borderRadius={2}
                                >
                                  <Typography className={classes.overlayNumber}>
                                    {(index + 1).toLocaleString("en-US", {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    })}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box width={2 / 3}>
                                <Typography variant="overline">
                                  {timing}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  className={classes.itineraryTitle}
                                >
                                  {title}
                                </Typography>
                                <Typography variant="subtitle2">
                                  {subtitle}
                                </Typography>
                              </Box>
                            </Box>
                            {index < itineraryItems.length - 1 && <Divider />}
                          </>
                        )
                      )}
                    </Box>
                  </div>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} md={10}>
                  {sliderImages?.length > 0 && (
                    <ImageSliderWithText
                      data={sliderImages}
                      loading={false}
                      sliderImagesTitles={sliderImagesTitles}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                spacing={3}
                className={classes.sectionWrapper}
              >
                <Grid item xs={12} md={6}>
                  <Typography variant="h2">
                    {informationSectionTitle}
                  </Typography>

                  <Box mt={4}>
                    <div className={classes.infoBlock}>
                      <Typography variant="h3">
                        {translations?.meetingTime}
                      </Typography>
                      <Typography variant="subtitle1">{meetingTime}</Typography>
                    </div>
                    <Divider />
                    <Grid container spacing={3} className={classes.infoBlock}>
                      <Grid item xs={6}>
                        <Typography variant="h3">Meeting point</Typography>
                        <Typography>{meetingCityLocation}</Typography>
                        <Box mt={2}>
                          <Button
                            variant="outlined"
                            href={`https://www.google.com/maps/dir//${meetingLocation?.lat},++${meetingLocation?.lon}/@${meetingLocation?.lat},${meetingLocation?.lon},17z/data=!4m6!4m5!1m0!1m3!2m2!1d${meetingLocation?.lon}!2d${meetingLocation?.lat}`}
                          >
                            {translations?.getDirection}
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <ReactMapGL
                          {...mapViewport}
                          mapboxApiAccessToken={
                            "pk.eyJ1Ijoic29ieXlpYWwiLCJhIjoiY2s1ODhuNmt6MGt0bTNmbGp0ajdsd3FiNSJ9.q_W4-_adsd7Uw-47IqfK2g"
                          }
                          mapStyle="mapbox://styles/sobyyial/ck58co6jc1ynr1clg35maix2m"
                          onViewportChange={viewport =>
                            setMapViewport(viewport)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    <div className={classes.infoBlock}>
                      <Typography variant="h3">
                        {translations?.duration}
                      </Typography>
                      <Typography>{meetingDuration}</Typography>
                    </div>
                    <Divider />
                    <div className={classes.infoBlock}>
                      <Typography variant="h3">
                        {translations?.language}
                      </Typography>
                      <Typography>{meetingLanguage}</Typography>
                    </div>
                    <Divider />
                    <div className={classes.infoBlock}>
                      <Typography variant="h3">
                        {translations?.notIncluded}
                      </Typography>
                      <ul>
                        {meetingNotIncludedBullets?.map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <Divider />
                    <div className={classes.infoBlock}>
                      <Typography variant="h3">
                        {translations?.specialNotes}
                      </Typography>
                      <ul>
                        {meetingSpecialNotesBullets?.map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <Divider />
                    <div
                      className={classes.infoBlock}
                      style={{ marginBottom: 0 }}
                    >
                      <Typography variant="h3">
                        {translations?.tourProgram}
                      </Typography>
                      <Box mt={1.5}>
                        <Button
                          variant="outlined"
                          endIcon={<VerticalAlignBottomIcon />}
                          href={`https:${tourProgramPdf}`}
                        >
                          {translations?.downloadProgram}
                        </Button>
                      </Box>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Hidden smDown>
              <Grid item md={3} xs={12}>
                <StickyCard />
              </Grid>
            </Hidden>
          </Grid>
        </ThemeProvider>
      </Container>
      <CustomDivider extendRight />
      <TourCardCarousel
        locale={locale}
        title={translations?.similarTour}
        subtitle="Creating value & adding destination meaning for Chinese living in or visiting Portugal"
        currencyString={translations?.euro}
      />

      <CustomDivider extendLeft />
      <Textdetail
        customImage={querySectionImage}
        locale={locale}
        defaultValue="Tours"
      />
      <CustomDivider extendLeft />
      <TripAdvisor translations={translations} locale={locale} width={width} />
      <Footer hidePartners />
    </div>

    // </IntlContextConsumer>
  )
}

export default withWidth()(TourDetail)

import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import TourDetail from "./tourDetails"
import Layout from "../../components/Layout"

export const query = graphql`
  query($slug: String, $locale: String!) {
    contentfulTourDetailModel(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      slug
      tourTitle
      seoTitle
      seoDescription
      stickyNoteTitle
      customDatabaseId
      tourDescription {
        childMarkdownRemark {
          html
        }
      }
      tourMainImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      tourPricingReference {
        totalPersonsLimit
        fixedTourPrice
        isFixedPriceTour
        perPersonPricing
        childPricingPercentageToAdult
        eachIncrementalExtra
        eachIncrementalExtraPricing
        eachFixedPriceExtraText
        eachFixedPriceExtraPricing
        personsLimitForFixedPriceExtraIncrement
        time
        isDisableCalender
        disableDaysLimit
        disableFirstDate
        disableSecondDate
        disableThirdDate
        disableFourthDate
        disableFifthDate
        disableSixthDate
        disableSeventhDate
        disableEighthDate
        disableNinthDate
        disableTenthDate
        enableWeekDays
        enablePayment
      }
      tourLocationDescription
      tourTime
      tourWaitingTime
      tourWaitingTime
      tourLanguage
      whatsIncludedTitle
      whatsIncludedListItems
      whyTourTitle
      whyTourListItems
      whyTourSubTitle
      itinerarySectionTitle
      itineraryTimings
      itinerarySectionSubTitle
      itineraryTitles
      itinerarySubTitles
      informationSectionTitle
      meetingTime
      meetingCityLocation
      meetingDuration
      meetingLanguage
      meetingLocation {
        lon
        lat
      }
      meetingDuration
      meetingLocationTiming
      meetingNotIncludedBullets
      meetingSpecialNotesBullets

      cityReference {
        slug
        cityName
      }
      itineraryImages {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      sliderImages {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      sliderImagesTitles
      tourProgramPdf {
        file {
          url
        }
      }
      tourDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulTourPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          couponCode
          couponDiscount
          querySectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
          queryTopics
        }
      }
    }

    allContentfulTourDetailModel(
      filter: { slug: { ne: $slug }, node_locale: { eq: $locale } }
      sort: { fields: [createdAt], order: ASC }
    ) {
      edges {
        node {
          slug
          tourLocationDescription
          tourTitle
          seoTitle
          seoDescription
          tourPricingReference {
            isFixedPriceTour
            fixedTourPrice
            perPersonPricing
          }
          cardDescription {
            cardDescription
          }
          tourMainImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
const TourDetailTemplate = ({
  location,
  pageContext,
  data: {
    contentfulTourDetailModel,
    allContentfulTourPageModel,
    allContentfulTourDetailModel,
    allContentfulLandingPageModel,
  },
}) => {
  const contentfulTourPageModel = allContentfulTourPageModel?.edges?.[0]?.node
  const [disableWeekDays, setDisableWeekDays] = useState([])



  const getDisableDays = useCallback(() => {
    const enableTourDays =
      contentfulTourDetailModel?.tourPricingReference?.enableWeekDays

    const weekDays = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    }

    const daysOff = []
    for (const day in weekDays) {
      if (!enableTourDays.includes(day)) daysOff.push(weekDays[day])
    }
    return daysOff
  }, [contentfulTourDetailModel.tourPricingReference.enableWeekDays])

  useEffect(() => {
    const diableDays = getDisableDays()
    setDisableWeekDays(diableDays)
  }, [getDisableDays])

  const disableDates = [
    contentfulTourDetailModel?.tourPricingReference?.disableFirstDate,
    contentfulTourDetailModel?.tourPricingReference?.disableSecondDate,
    contentfulTourDetailModel?.tourPricingReference?.disableThirdDate,
    contentfulTourDetailModel?.tourPricingReference?.disableFourthDate,
    contentfulTourDetailModel?.tourPricingReference?.disableFifthDate,
    contentfulTourDetailModel?.tourPricingReference?.disableSixthDate,
    contentfulTourDetailModel?.tourPricingReference?.disableSeventhDate,
    contentfulTourDetailModel?.tourPricingReference?.disableEighthDate,
    contentfulTourDetailModel?.tourPricingReference?.disableNinthDate,
    contentfulTourDetailModel?.tourPricingReference?.disableTenthDate,
  ]

  const { seoTitle, seoDescription } = contentfulTourDetailModel

  const {
    hateContactFormTitle,
    hateContactFormSubTitle,
    hateContactFormLogos,
    hateContactFormLogosId,
    queryTopics,
  } = allContentfulLandingPageModel.edges[0].node

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      backgroundcolor={"#FAF5EF"}
      Textcolor={"black"}
      selected={"Tours"}
      button={"#FF9A8B"}
    >
      
      <TourDetail
        enablePayment={
          contentfulTourDetailModel?.tourPricingReference?.enablePayment
        }
        couponCode={contentfulTourPageModel?.couponCode}
        couponDiscount={contentfulTourPageModel?.couponDiscount}
        stickyNoteTitle={contentfulTourDetailModel?.stickyNoteTitle}
        totalPersonsLimit={
          contentfulTourDetailModel?.tourPricingReference?.totalPersonsLimit
        }
        childPricingPercentageToAdult={
          contentfulTourDetailModel?.tourPricingReference
            ?.childPricingPercentageToAdult
        }
        location={contentfulTourDetailModel?.location}
        customDatabaseId={contentfulTourDetailModel?.customDatabaseId}
        tourTitle={contentfulTourDetailModel?.tourTitle}
        tourMainImage={contentfulTourDetailModel?.tourMainImage?.fluid}
        tourDescription={
          contentfulTourDetailModel?.tourDescription?.childMarkdownRemark?.html
        }
        tourLocationDescription={
          contentfulTourDetailModel?.tourLocationDescription
        }
        tourTime={contentfulTourDetailModel?.tourTime}
        tourWaitingTime={contentfulTourDetailModel?.tourWaitingTime}
        tourLanguage={contentfulTourDetailModel?.tourLanguage}
        whatsIncludedTitle={contentfulTourDetailModel?.whatsIncludedTitle}
        whatsIncludedListItems={
          contentfulTourDetailModel?.whatsIncludedListItems
        }
        whyTourTitle={contentfulTourDetailModel?.whyTourTitle}
        whyTourListItems={contentfulTourDetailModel?.whyTourListItems}
        whyTourSubTitle={contentfulTourDetailModel?.whyTourSubTitle}
        itinerarySectionTitle={contentfulTourDetailModel?.itinerarySectionTitle}
        itineraryTimings={contentfulTourDetailModel?.itineraryTimings}
        itinerarySectionSubTitle={
          contentfulTourDetailModel?.itinerarySectionSubTitle
        }
        itineraryTitles={contentfulTourDetailModel?.itineraryTitles}
        itinerarySubTitles={contentfulTourDetailModel?.itinerarySubTitles}
        informationSectionTitle={
          contentfulTourDetailModel?.informationSectionTitle
        }
        meetingTime={contentfulTourDetailModel?.meetingTime}
        meetingDuration={contentfulTourDetailModel?.meetingDuration}
        meetingLocation={contentfulTourDetailModel?.meetingLocation}
        meetingLanguage={contentfulTourDetailModel?.meetingLanguage}
        meetingLocationTiming={contentfulTourDetailModel?.meetingLocationTiming}
        meetingNotIncludedBullets={
          contentfulTourDetailModel?.meetingNotIncludedBullets
        }
        meetingSpecialNotesBullets={
          contentfulTourDetailModel?.meetingSpecialNotesBullets
        }
        meetingCityLocation={contentfulTourDetailModel?.meetingCityLocation}
        citySlug={contentfulTourDetailModel?.cityReference?.slug}
        cityName={contentfulTourDetailModel?.cityReference?.cityName}
        itineraryImages={contentfulTourDetailModel?.itineraryImages}
        sliderImages={contentfulTourDetailModel?.sliderImages}
        sliderImagesTitles={contentfulTourDetailModel?.sliderImagesTitles}
        tourProgramPdf={contentfulTourDetailModel?.tourProgramPdf?.file?.url}
        disableWeekDays={disableWeekDays}
        disableDates={disableDates}
        disableDaysLimit={
          contentfulTourDetailModel?.tourPricingReference?.disableDaysLimit
        }
        isDisableCalender={
          contentfulTourDetailModel?.tourPricingReference?.isDisableCalender
        }
        fixedTourPrice={
          contentfulTourDetailModel?.tourPricingReference?.fixedTourPrice
        }
        isFixedPriceTour={
          contentfulTourDetailModel?.tourPricingReference?.isFixedPriceTour
        }
        perPersonPricing={
          contentfulTourDetailModel?.tourPricingReference?.perPersonPricing
        }
        eachIncrementalExtra={
          contentfulTourDetailModel?.tourPricingReference?.eachIncrementalExtra
        }
        eachIncrementalExtraPricing={
          contentfulTourDetailModel?.tourPricingReference
            ?.eachIncrementalExtraPricing
        }
        eachFixedPriceExtraText={
          contentfulTourDetailModel?.tourPricingReference
            ?.eachFixedPriceExtraText
        }
        eachFixedPriceExtraPricing={
          contentfulTourDetailModel?.tourPricingReference
            ?.eachFixedPriceExtraPricing
        }
        personsLimitForFixedPriceExtraIncrement={
          contentfulTourDetailModel?.tourPricingReference
            ?.personsLimitForFixedPriceExtraIncrement
        }
        tourTimings={contentfulTourDetailModel?.tourPricingReference?.time}
        similarToursCards={allContentfulTourDetailModel.edges}
        hateContactFormTitle={hateContactFormTitle}
        hateContactFormSubTitle={hateContactFormSubTitle}
        hateContactFormLogos={hateContactFormLogos}
        hateContactFormLogosId={hateContactFormLogosId}
        querySectionImage={contentfulTourPageModel?.querySectionImage}
        queryTopics={queryTopics}
        seoTitle={seoTitle}
        seoDescription={seoDescription}
      />
    </Layout>
  )
}

export default TourDetailTemplate
